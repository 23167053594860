import { createTheme } from "@material-ui/core/styles"

const theme = createTheme({
  status: {
    success: "#00ff00",
    action: "#777777",
  },

  palette: {
    type: "dark",

    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#228636",
    },

    common: {
      darkDarkRed: "#100800",
      localOrdDark: "#560e76",
      localOrd: "#8010b3",
      dineInDark: "#341b2e",
      dineInMed: "#401f38",
      dineIn: "#4c2743",
      received: "#606060",
      preparing: "#3e4bc5",
      ready: "#00939f",
      done: "#228636",
      lightBlue: "#58a6ff",
      darkBlue: "#0d2d6b",
      lightPink: "#f778ba",
      darkPink: "#5d103e",
      black: "#000000",
      blackOne: "#101010",
      blackTwo: "#202020",
      blackThree: "#303030",
      blackFour: "#404040",
      blackFive: "#505050",
      blackSix: "#606060",
      blackSeven: "#707070",
      blackEight: "#808080",
      blackNine: "#909090",
      blackTen: "#A0A0A0",
      blackEleven: "#B0B0B0",
      blackTweleve: "#C0C0C0",
      blackThirteen: "#D0D0D0",
      blackFourteen: "#E0E0E0",
      blackFifteen: "#F0F0F0",
      redSelected: "#e74c3c",
      redSelectedDark: "#951a0d",
      orangeRed: "#ff4500",
      yellow: "#ffff00",

      darkGreen: "#174a21",
      midGreen: "#228636",
      green: "#00ff00",
      orange: "#ffa500",
      darkOrange: "#b86800",
      red: "#ff0000",
      darkRed: "#b62324",
      grey: "#777777",
      darkGrey: "#555555",
      almostWhite: "#f3f3f3",
      offWhite: "#e6e6e6",
      seventyWhite: "#b3b3b3",
      white: "#fff",

      alertIcon: "#ef5350",
      aletBg: "#fdeded",
    },
  },
  typography: {
    h1: {
      fontSize: "4.5rem",
      fontFamily: "Roboto",
      fontWeight: 900,
    },
    h3: {
      fontSize: "3rem",
      lineHeight: "3rem",
      fontFamily: "Roboto",
      fontWeight: 700,
    },
    h4: {
      lineHeight: "2.5rem",
      fontSize: "2.5rem",
      fontFamily: "Roboto",
      fontWeight: 400,
    },
    h5: {
      fontSize: "1.7rem",
      fontFamily: "Roboto",
      fontWeight: 300,
      lineHeight: "1.7rem",
    },

    h6: {
      lineHeight: "1.6rem",
      fontSize: "1.4rem",
      fontFamily: "Roboto",
      fontWeight: 400,
    },

    body1: {
      fontSize: "0.875rem",
      fontFamily: "Roboto",
      fontWeight: 400,
    },
    body2: {
      fontSize: "1.25rem",
      fontFamily: "Roboto",
      fontWeight: 400,
    },
  },
  overrides: {},
})

export default theme
